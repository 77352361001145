<template>
	<div>
		<div style="width: 900px;margin: 0 auto;">
			<div class="titles">{{info.title}}</div>
			<div v-html="content" class="vHtml"></div>
		</div>
		<bottom/>
	</div>
	
</template>

<script>
	import axios from 'axios';
	import * as api from '@/api/api.js'
	import bottom from '@/view/MinHome/Home/components/bottom.vue'
	export default {
		components:{
			bottom
		},
		data() {
			return {
				info:'',
				content:'',
			}
		},
		mounted() {
			this.getData();
		},
		methods:{
			getData(){
				var that = this;
				var id = this.$route.query.id;
				console.log(id,'dddd')
				var params = {
					id:id,
				}
				axios.post(api.url+api.details, params).then(function(res) {
					console.log(res,'444')
					if(res.status !== 200) return
					that.info = res.data.data
					that.content = that.info.content ? decodeURIComponent(that.info.content) :'';
					
					console.log(that.content,'that.content')
				})
			}
		}
	}
</script>

<style>
	.titles {
		font-size: 20px;
		font-weight: bold;
		padding: 30px;
	}
	.vHtml p {
		padding: 10px 0;
	}
</style>
